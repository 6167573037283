<template>
  <div class="tabs is-boxed is-right">
    <ul>
      <li
        :class="{
          'is-active': tab === 'account-history-companion'
        }"
        data-test-id="product-tabs-companion"
      >
        <a
          @click="$emit('change-tab', 'account-history-companion')"
          :class="{ 'p-4': $mq === 'mobile' }"
        >
          <!-- Default tradeportal styles, the text is semibold not matter active or not -->
          <span
            v-if="!$motors"
            class="is-hidden-mobile has-text-weight-semibold"
          >Companion
          </span>
          <!-- Motors styles, the text is bold or medium depending if is active or not.Consider use this for both in the future -->
          <span
            v-else
            :class="[
              'is-hidden-mobile',
              tab === 'account-history-companion' ? 'has-text-weight-bold' : 'has-text-weight-medium',
            ]"
          >Price check
          </span>
          <img
            class="is-hidden-tablet icon is-marginless is-small"
            :src="require(`core/img/icons/percayso-logo.svg`)"
          >
        </a>
      </li>
      <li
        v-if="hasAutoCheck"
        :class="{
          'is-active': tab === 'account-history-check'
        }"
        data-test-id="product-tabs-autocheck"
      >
        <a
          v-if="hasAutoCheck"
          @click="$emit('change-tab', 'account-history-check')"
          :class="{ 'p-4': $mq === 'mobile' }"
        >
          <!-- Default tradeportal styles, the text is semibold not matter active or not -->
          <span
            v-if="!$motors"
            class="is-hidden-mobile has-text-weight-semibold"
          >AutoCheck</span>
          <!-- Motors styles, the text is bold or medium depending if is active or not -->
          <span
            v-else
            :class="[
              'is-hidden-mobile',
              tab === 'account-history-check' ? 'has-text-weight-bold' : 'has-text-weight-medium',
            ]"
          >History check</span>
          <i
            :class="[
              tab === 'account-history-check'
                ? 'has-text-info'
                : 'has-text-light'
            ]"
            class="fas fa-check is-hidden-tablet icon is-marginless is-small"
          />
        </a>
      </li>

      <li
        v-if="!$motors"
        :class="{ 'is-active': tab === 'account-history-vpp' }"
        data-test-id="product-tabs-vpp"
      >
        <a
          @click="$emit('change-tab', 'account-history-vpp')"
          :class="{ 'p-4': $mq === 'mobile' }"
        >
          <span class="is-hidden-mobile has-text-weight-semibold">
            Vehicle Profile
          </span>
          <i
            :class="[
              tab === 'account-history-vpp' ? 'has-text-info' : 'has-text-light'
            ]"
            class="fas fa-search is-hidden-tablet icon is-marginless is-small"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AccountHistoryProductTabs',
  props: {
    hasAutoCheck: {
      type: Number,
      default: () => 0
    },
    tab: {
      type: String,
      required: true
    },
    colour: {
      type: String,
      required: true
    },
    preview: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['hasAutoCheck'])
  }
}
</script>
